<template>
  <div :class="$style.root">
    <main :class="$style.page">
      <slot />
    </main>

    <SharedGtagConsentComponent v-once />

    <SharedFooterComponent v-once />
  </div>
</template>

<script setup>
  const menuOpen = ref(false)
</script>

<style module>
.root {
  composes: font-default from global;

  display: flex;
  flex-direction: column;

  min-height: var(--100vh);
}

.page {
  flex: 1;

  display: flex;
  flex-direction: column;
}

.grid {
  margin-top: var(--unit--default);
}

.page > * {
  flex: 1;
}
</style>
